@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* * {
    box-sizing: border-box;
    padding: 0%;
    margin: 0%;
    font-family: "Poppins", sans-serif;
} */

.boddy {
    height: auto;
    background-color:rgb(247,249,253);
    display: grid;
    place-items: center;
}

#section-partner{
    display: grid;
    place-items: center;
}

#section-partner h1 {
    font-size: 3rem;
    padding: 20px;
    margin: 20px 0px;
}

.slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    overflow: hidden;
    background: rgb(251, 251, 251);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.slider-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    animation: scrolling 20s linear infinite;

}

@keyframes scrolling {

    0% {
        transform: translateX(80%);
    }

    100% {
        transform: translateX(-20%);
    }
}

.slider-items img {
    width: 12%;
    margin: 20px;

}